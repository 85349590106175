<template>
    <DashboardWrapper>
        <div class="container mx-auto">
            <div class="mx-4 sm:mx-8"> 
                <h2 class="text-white pb-4 mb-4 uppercase text-xl font-extrabold">
                    Alle leden
                </h2>
                <div class="flex justify-end">
                    <div @click="showModal('invite')" class="my-auto text-white cursor-pointer flex group">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-1 transition-all duration-150 group-hover:text-gray-400 transform group-hover:-translate-y-1" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
                        </svg>
                        <p class="mr-6 group-hover:text-gray-400 transition-all duration-150">
                            Leden uitnodigen
                        </p>
                    </div>
                    <SearchInput v-model:value="searchQuery" placeholder="Lid zoeken..." theme="dark"/>
                </div>
            </div>

            <div class="flex flex-col">
                <div class="overflow-x-auto">
                    <div class="py-6 align-middle inline-block min-w-full mx-4 md:mx-0 sm:px-6 lg:px-8">
                        <div class="shadow overflow-hidden">
                            <table class="min-w-full divide-y divide-gray-200">
                                <thead>
                                    <tr>
                                        <th v-for="row in rows" :key="row.key" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            <div class="flex items-center cursor-pointer" @click="sort(row.key)">
                                                <div>{{row.text}}</div>
                                                <div v-if="currentSort === row.key">
                                                    <svg class="ml-1 w-4 h-4 transform duration-200" :class="[currentSortDir == 'asc' ? '-rotate-180': '']" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                                                    </svg>
                                                </div>
                                                <div v-else class="ml-1 w-4 h-4">
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="divide-y divide-gray-500">
                                    <template v-if="members.length">
                                        <tr v-for="member in sortedMembers" :key="member.name">
                                            <MemberRow :member="member" @open="showModal" @memberData="this.selectedMember = member" />
                                        </tr>
                                    </template>
                                    <tr v-else>
                                        <td :colspan="rows.length" class="px-4 py-5 whitespace-nowrap text-sm font-medium text-center text-white">
                                            Er zijn momenteel geen leden
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </DashboardWrapper>

    <!-- Edit Member Modal-->
    <Modal v-if="modalVisible && selectedModal == 'edit'" @closed="closeModal" >
        <template v-slot:header>
            <div class="flex">
                <svg class="w-6 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                </svg>
                <h2 class="uppercase text-xl font-extrabold">
                    Lid wijzigen
                </h2>
            </div>
        </template> 
        <template v-slot:body> 
            <p class="pb-6 mt-2">
                Gegevens van <strong>{{ selectedMember.name }}</strong> aanpassen
            </p>
            <SelectInput label="Team" border="enable"/>
        </template>  
        <template v-slot:footer>     
            <div class="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                <button type="button" class="button w-full inline-flex justify-center sm:ml-3 sm:w-auto ">
                    Opslaan
                </button>
                <button @click="closeModal" type="button" class="button-black w-full inline-flex justify-center mt-3 sm:mt-0 sm:w-auto ">
                    Annuleren
                </button>
            </div>
        </template>
    </Modal>

    <!-- Delete Member Modal-->
    <Modal v-if="modalVisible && selectedModal == 'delete'" @closed="closeModal" >    
        <template v-slot:header>
            <div class="flex">
                <svg class="w-6 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                </svg>
                <h2 class="uppercase text-xl font-extrabold">
                    Lid verwijderen
                </h2>
            </div>
        </template>
        <template v-slot:body>
            <p class="pb-6 mt-2">
                Weet je zeker dat je <strong>{{ selectedMember.name }}</strong> wilt verwijderen uit je club?
            </p>
        </template>
        <template v-slot:footer>           
            <div class="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                <button type="button" class="button w-full inline-flex justify-center sm:ml-3 sm:w-auto ">
                    Verwijderen
                </button>
                <button @click="closeModal" type="button" class="button-black w-full inline-flex justify-center mt-3 sm:mt-0 sm:w-auto ">
                    Annuleren
                </button>
            </div>
        </template>
    </Modal>    

    <!-- Invite Member Modal-->
    <Modal v-if="modalVisible && selectedModal == 'invite'" @closed="closeModal" >
        <template v-slot:header>
            <div class="flex">
                <svg class="w-6 mr-1 text-scorelit-gray" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
                </svg>
                <h2 class="uppercase text-xl font-extrabold">
                    Leden uitnodigen
                </h2>
            </div>
        </template>
        <template v-slot:body>    
            <TextInput label="Uitnodigen via e-mail" border="enable" placeholder="E-mailadres" class="mt-10"/>
            <div class="flex items-center my-5">
                <div class="border-t-2 w-full"></div>
                <div class="mx-5">Of</div>
                <div class="border-t-2 w-full"></div>
            </div>
            <button class="button-black">Ledenlijst importeren</button>
        </template>
        <template v-slot:footer>    
            <div class="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                <button type="button" class="button w-full inline-flex justify-center sm:ml-3 sm:w-auto ">
                    Uitnodigen
                </button>
                <button @click="closeModal" type="button" class="button-black w-full inline-flex justify-center mt-3 sm:mt-0 sm:w-auto ">
                    Annuleren
                </button>
            </div>
        </template>  
    </Modal>    
</template>

<script>
import DashboardWrapper from "@/views/wrappers/Dashboard";
import MemberRow from "@/components/rows/MemberRow";
import TextInput from "@/components/forms/TextInput";
import SearchInput from "@/components/forms/SearchInput";
import SelectInput from "@/components/forms/SelectInput";
import Modal from "@/components/modals/Modal";

export default {
    name: "Members",
    components: {
        DashboardWrapper,
        MemberRow,
        TextInput,
        SearchInput,
        SelectInput,
        Modal,
    },

    data() {
        return {
            modalVisible: false,
            selectedModal: '',
            selectedMember: '',
            searchQuery: '',

            currentSort: 'name',
            currentSortDir: 'asc',

            rows: [
                { key:'name', text:'Naam' },
                { key:'email', text:'Email' },
                { key:'birthdate', text:'Geboortedatum' },
                { key:'team', text:'Team' },
                { key:'analysis', text:'Analyses' },
                { key:'status', text:'Status' },
            ],

            // Testdata
            members: [
                { name: 'Jeroen Klaver',         email:'JeroenKlaver.com',           birthdate:'17-07-1999',     team: 'U22-1',      analysis: 3,      status: 'Aangemeld'   },
                { name: 'Noah de Vries',         email:'NoahDeVries@hotmail.com',    birthdate:'03-01-1999',     team: 'U22-1',      analysis: 0,      status: 'Aangemeld'   },
                { name: 'Daan van Dijk',         email:'DaanvDijk@gmail.com',        birthdate:'22-11-2000',     team: 'U22-2',      analysis: 0,      status: 'Uitgenodigd' },
                { name: 'Emma Janssen',          email:'E.Janssen@gmail.com',        birthdate:'27-12-2002',     team: 'U18-1',      analysis: 1,      status: 'Aangemeld'   },
                { name: 'Nynke Spoelstra',       email:'NynkeSpoelstra@gmail.com',   birthdate:'15-03-2002',     team: 'U18-3',      analysis: 2,      status: 'Uitgenodigd' },
                { name: 'Amin Meijer',           email:'AminMeijer@chello.nl',       birthdate:'11-05-2006',     team: 'U16-2',      analysis: 5,      status: 'Uitgenodigd' },
            ],
        }
    },  

    methods: {
        sort:function(s) {
            if (s === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            }
            this.currentSort = s;
        },

        closeModal() {
            this.modalVisible = false;
        },

        showModal(type) {
            this.selectedModal = type;
            this.modalVisible = true;        
        },
    },

    computed: {
        sortedMembers:function(){
            return this.resultQuery.sort((a,b) => {
                let modifier = 1;
                if(this.currentSortDir === 'desc') modifier = -1;
                if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                return 0
            }); 
        },

        resultQuery(){
            if(this.searchQuery){
            return this.members.filter(member=>{
            return this.searchQuery.toLowerCase().split(' ').every(v => member.name.toLowerCase().includes(v))
            })
            }
            else {
                return this.members;
            }
        },
    }

}

</script>

<style>
/* 
    body {
        overflow-y: hidden; 
        overflow-x: hidden; 
}
*/
</style>